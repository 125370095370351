<template>
    <div class="video_con">
        <h4>{{des}}</h4>
        <video controls class="video" ref="video"></video>
    </div>
</template>
<script>
    var Hls = require('hls.js');
    export default {
        data() {
            return {
                hls: null,
                video_url: '',
                des:''
            }
        },
        mounted() {
            this.video_url = this.$route.query.url;
            this.des = this.$route.query.des;
            this.getStream(this.video_url);
        },
        methods: {
            getStream(source) {
                if (Hls.isSupported()) {
                    this.hls = new Hls();
                    this.hls.loadSource(source);
                    this.hls.attachMedia(this.$refs.video);
                    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        console.log("加载成功");
                        // this.$refs.video.play();
                    });
                    this.hls.on(Hls.Events.ERROR, (event, data) => {
                        // console.log(event, data);
                        // 监听出错事件
                        console.log("加载失败");
                    });
                } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                    this.$refs.video.src = 'https://video-dev.github.io/streams/x36xhzz/x36xhzz.m3u8';
                    this.$refs.video.addEventListener('loadedmetadata', function () {
                        // this.$refs.video.play();
                    });
                }
            },
            // 停止流
            videoPause() {
                if (this.hls) {
                    this.$refs.video.pause();
                    this.hls.destroy();
                    this.hls = null;
                }
            }
        },
    }
</script>
<style>
    .video{
        width: 400px;
    }
</style>